import api from '@whatsnxt/core-util/src/ApiClient/xiorInstance';

const GET_POPULAR_QUERY = () => `
  query GetPopularPosts {
    popularPosts {
      _id
      title
      slug
      description
      categoryId
      categoryName
      published
      listed
      imageUrl
    }
  }
`;

export const SidebarAPI = {
  getPopular: async function () {
    const { data } = await api.request({
      data: {
        query: GET_POPULAR_QUERY,
      },
    }) as any;

    return data.data
      ? data.data.popularPosts
      : [];
  },
};

