'use client';

import React from 'react';
import { useDispatch } from 'react-redux';
import { Anchor, Box, Button, Divider, Drawer, Group, ScrollArea, rem } from '@mantine/core';
import { IconLogin, IconLogout } from '@tabler/icons-react';
import Link from 'next/link';
import { redirect } from 'next/navigation';
import useAuth from '../../../hooks/Authentication/useAuth';
import { setSelectTag } from '../../../store/slices/contentSlice';
import { AppDispatch } from '../../../store/store';
import { AuthenticatedUserType } from '@/context/Authentication/authTypes';
type Link = {
  url: string;
  title: string;
  linkType: string;
};
interface INavbarMobile {
  navLinks: any[];
  authenticatedMenuLinks: Link[];
  user?: AuthenticatedUserType | null;
  drawerOpened: any;
  closeDrawer: any;
}
export const NavbarMobile = ({
  user,
  navLinks,
  authenticatedMenuLinks,
  drawerOpened,
  closeDrawer
}: INavbarMobile) => {
  const {
    isAuthenticated,
    logout,
    loading
  } = useAuth();
  const dispatch = useDispatch<AppDispatch>();
  const handleLogin = () => {
    closeDrawer();
    redirect('/auth/authentication');
  };
  const handleLogout = async () => {
    await logout();
    closeDrawer();
  };

  // Merge navLinks and authenticatedMenuLinks
  const combinedLinks = navLinks.filter(link => link.title !== 'Write' || link.title === 'Write' && user?.creator).concat(isAuthenticated ? authenticatedMenuLinks : []);
  return <Drawer opened={drawerOpened} onClose={closeDrawer} size="100%" padding="md" title="Navigation" hiddenFrom="lg" zIndex={1000000} data-sentry-element="Drawer" data-sentry-component="NavbarMobile" data-sentry-source-file="index.tsx">
      <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md" data-sentry-element="ScrollArea" data-sentry-source-file="index.tsx">
        {combinedLinks.map((link: any, index) => <Box key={index}>
            <Divider my="md" />
            <Anchor onClick={() => {
          dispatch(setSelectTag(null));
          closeDrawer();
        }} href={link.url} style={{
          display: 'grid',
          lineHeight: 1,
          padding: '8px 12px',
          borderRadius: 'var(--mantine-radius-sm)',
          textDecoration: 'none',
          color: 'var(--mantine-color-gray-7)',
          fontSize: 'var(--mantine-font-size-sm)',
          fontWeight: 500
        }} component={Link}>
              {link.title}
            </Anchor>
          </Box>)}

        <Divider my="lg" data-sentry-element="Divider" data-sentry-source-file="index.tsx" />
        <Group justify="center" grow pb="xl" px="md" data-sentry-element="Group" data-sentry-source-file="index.tsx">
          {!isAuthenticated ? <Button loading={loading} disabled={loading} leftSection={<IconLogin />} onClick={() => {
          handleLogin();
          closeDrawer();
        }} variant="filled" color="red" component={Link} href="/auth/authentication">
              Log in
            </Button> : <Button loading={loading} disabled={loading} onClick={() => {
          handleLogout();
          closeDrawer(); // Close drawer after clicking Logout
        }} color="red" leftSection={<IconLogout size={18} />}>
              Logout
            </Button>}
        </Group>
      </ScrollArea>
    </Drawer>;
};