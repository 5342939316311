export const GET_POSTS_HISTORY_QUERY = () => `
  query GetContentHistory(
    $start: Float!,
    $limit: Float!,
    $type: String!,
    $search: String
  ) {
    retrieveContentHistory(start: $start, limit: $limit, type: $type, search: $search ) {
      posts {
        _id
        title
        slug
        description
        categoryId
        categoryName
        imageUrl
        subCategory
        nestedSubCategory
        tutorial
        tutorials {
          title
          description
        }
        published
        listed
        updated_at
      }
      totalRecords
    }
  }
`;

export const DELETE_POST_MUTATION = () => `
  mutation DeletePost(
    $postId: String!
  ) {
    deletePost(
      postId: $postId
    )
  }
`;

export const DOWNLOAD_EBOOK_QUERY = () => `
  query GenerateEbook($id: String!) {
    generateEbook(id: $id) {
      filename
      fileContent
    }
  }
`;

export const CREATE_TUTORIAL_FROM_BLOGS = `
  mutation CreateTutorialFromBlogs($blogIds: [String!]!, $title: String!) {
    CreateTutorialFromBlogs(blogIds: $blogIds, title: $title) {
      _id
      title
    }
  }
`;

export const DELETE_TUTORIAL_MUTATION = () => `
  mutation DeleteTutorial(
    $tutorialId: String!
  ) {
    deleteTutorial(
      tutorialId: $tutorialId
    )
  }
`;

export const PUBLISH_POST_MUTATION = () => `
  mutation PublishPost(
    $postId: String!,
    $publish: Boolean!
  ) {
    publishPost(
      postId: $postId,
      publish: $publish
    ) {
      _id
      title
      description
      categoryName
      categoryId
      updated_at
      slug
      listed
      published
    }
  }
`;

export const LIST_POST_MUTATION = () => `
  mutation ListPost(
    $postId: String!,
    $list: Boolean!
  ) {
    listPost(
      postId: $postId,
      list: $list
    ) {
      _id
      title
      description
      categoryName
      categoryId

      updated_at
      slug
      listed
      published
    }
  }
`;
