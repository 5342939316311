export const GET_POSTS_QUERY = () => `
  query GetPosts(
    $start: Float!,
    $limit: Float!,
    $type: String!
  ) {
    posts(start: $start, limit: $limit, type: $type) {
      posts {
        _id
        title
        slug
        description
        categoryId
        categoryName
        imageUrl
        subCategory
        nestedSubCategory
        tutorial
        tutorials {
          title
          description
        }
        published
        listed
        updated_at
      }
      totalRecords
    }
  }
`;

export const GET_POST_QUERY = () => `
  query GetPost(
    $id: String!
  ) {
    post(id: $id) {
      _id
      title
      slug
      description
      categoryId
      categoryName
      imageUrl
      subCategory
      nestedSubCategory
      tutorial
      tutorials {
        title
        description
      }
      published
      listed
    }
  }
`;

export const GET_POST_SLUG_QUERY = () => `
  query GetPostBySlug(
    $slug: String!
  ) {
    postBySlug(slug: $slug) {
      _id
      title
      slug
      description
      categoryId
      categoryName
      timeToRead
      imageUrl
      subCategory
      nestedSubCategory
      tutorial
      tutorials {
        title
        description
      }
      published
      listed
      updated_at
    }
  }
`;

export const GET_POSTS_CATEGORY_QUERY = () => `
  query GetPostsCategory(
    $categoryName: String!
  ) {
    postsByCategory(categoryName: $categoryName) {
      _id
      title
      slug
      published
      listed
      description
      categoryId
      categoryName
      imageUrl
      subCategory
      nestedSubCategory
      tutorial
      tutorials {
        title
        description
      }
    }
  }
`;

export const GET_COMMENTS_QUERY = () => `
  query getComments(
    $offset: Float!,
    $limit: Float!,
    $parentId: ID,
    $contentId: ID!,
  ) {
    getComments(
      offset: $offset,
      limit: $limit,
      parentId: $parentId,
      contentId: $contentId,
    ) {
      email
      content
      createdAt
      disLikedBy
      flags
      _id
      isHidden
      likedBy
      likes
      dislikes
      parentId
      updatedAt
      childCount
      flaggedBy
    }
  }
`;

export const POST_COMMENT_MUTATION = () => `
    mutation PostComment(
        $contentId: ID!,
        $content: String!,
        $email: String!,
        $parentId: ID
    ) {
        createComment(
            contentId: $contentId,
            content: $content,
            email: $email,
            parentId: $parentId
        ) {
            _id
            parentId
            content
            email
        }
    }
`;

export const EDIT_COMMENT_MUTATION = () => `
  mutation EditComment(
    $contentId: ID!,
    $content: String!,
    $email: String!,
    $id: ID!
  ) {
    editComment(
      contentId: $contentId,
      content: $content,
      email: $email,
      id: $id,
    ) {
      email,
      content,
      createdAt,
      disLikedBy,
      flags,
      _id,
      isHidden,
      likedBy,
      likes,
      parentId,
      updatedAt
    }
  }
`;

export const DELETE_COMMENT_MUTATION = () => `
  mutation DeleteComment(
    $id: ID!,
    $contentId: ID!,
    $email: String!
  ) {
    deleteComment(
      id: $id,
      contentId: $contentId,
      email: $email,
    )
  }
`;

export const TOGGLE_LIKE_MUTATION = () => `
  mutation ToggleLike(
    $id: ID!,
    $email: ID!
  ) {
    toggleLike(
      id: $id,
      email: $email
    ){
      email,
      content,
      createdAt,
      disLikedBy,
      flags,
      _id,
      isHidden,
      likedBy,
      likes,
      dislikes,
      parentId,
      updatedAt
    }
  }
`;

export const TOGGLE_DISLIKE_MUTATION = () => `
  mutation ToggleDislike(
    $id: ID!,
    $email: ID!
  ) {
    toggleDislike(
      id: $id,
      email: $email
    ){
      email,
      content,
      createdAt,
      disLikedBy,
      flags,
      _id,
      isHidden,
      likedBy,
      likes,
      dislikes,
      parentId,
      updatedAt
    }
  }
`;

export const FLAG_MUTATION = () => `
  mutation FlagComment(
    $id: ID!,
    $email: String!
  ) {
    flagComment(
      id: $id,
      email: $email
    ){
      _id,
      email,
      flags,
      flaggedBy,
    }
  }
`
