'use client';

import React, { useState, createContext, Suspense, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Navbar from './navbar/Index';
import { Provider } from 'react-redux';
import { store } from '../store/store';
import { ApolloProvider } from '@apollo/client';
import { client as apolloClient } from '../utils/apollo-client';
import { Footer } from '../components/_App/Footer';
import { createTheme, MantineProvider } from '@mantine/core';
import CustomProgressBar from '../components/_App/CustomProgressBar';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import { AuthProvider } from '../context/Authentication/AuthContext';
import { CloudinaryAssetsManageContextProvider } from '../context/CloudinaryAssetsManageContext';
import { GoTop } from '@whatsnxt/core-util';
import dynamic from 'next/dynamic';
// import PartyTownScripts from '@/components/PartyTownScripts';
import { MantineLoader } from '@whatsnxt/core-ui';
import { NavigationProgress } from '@mantine/nprogress';
const PartyTownScripts = dynamic(() => import('@/components/PartyTownScripts'), {
  ssr: false
});
interface MenuContextProps {
  menu: boolean;
  setMenu: React.Dispatch<React.SetStateAction<boolean>>;
}
const MenuContext = createContext<MenuContextProps>({
  menu: true,
  setMenu: () => {}
});
function Layout({
  children
}: {
  children: React.ReactNode;
}) {
  const {
    links,
    authenticatedMenuLinks,
    copyRight
  } = {
    links: [{
      title: 'Home',
      url: '/',
      linkType: '_self'
    }, {
      title: 'Blogs',
      url: '/blogs',
      linkType: '_self'
    }, {
      title: 'Tutorials',
      url: '/tutorials',
      linkType: '_self'
    }, {
      title: 'Courses',
      url: 'https://www.whatsnxt.in/courses',
      linkType: '_blank'
    }, {
      title: 'Write',
      url: '/form',
      linkType: '_self'
    }],
    authenticatedMenuLinks: [
    // { title: 'Profile', url: '/form', linkType: '_self' }, TODO: Not yet implemented
    {
      title: 'History',
      url: '/history/table',
      linkType: '_self'
    }
    // { title: 'Categories', url: '/category-dashboard', linkType: '_self' },
    ],
    copyRight: 'whatsnxt 2024. All rights reserved'
  };
  const [menu, setMenu] = useState(false);
  const queryClient = new QueryClient();
  useEffect(() => {
    setMenu(true);
  });
  const theme = createTheme({
    fontFamily: 'Open Sans, sans-serif'
  });
  return <MantineProvider data-sentry-element="MantineProvider" data-sentry-component="Layout" data-sentry-source-file="ClientLayout.tsx">
      <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="ClientLayout.tsx">
        <Provider store={store} data-sentry-element="Provider" data-sentry-source-file="ClientLayout.tsx">
          <ApolloProvider client={apolloClient} data-sentry-element="ApolloProvider" data-sentry-source-file="ClientLayout.tsx">
            <PartyTownScripts data-sentry-element="PartyTownScripts" data-sentry-source-file="ClientLayout.tsx" />
            <main className="app">
              <Suspense fallback={<MantineLoader />} data-sentry-element="Suspense" data-sentry-source-file="ClientLayout.tsx">
                <NavigationProgress color="#fe4a55" size={3} data-sentry-element="NavigationProgress" data-sentry-source-file="ClientLayout.tsx" />
                <CustomProgressBar data-sentry-element="CustomProgressBar" data-sentry-source-file="ClientLayout.tsx" />
              </Suspense>
              <Notifications zIndex={1000} data-sentry-element="Notifications" data-sentry-source-file="ClientLayout.tsx" />
              <MenuContext.Provider value={{
              menu,
              setMenu
            }} data-sentry-element="unknown" data-sentry-source-file="ClientLayout.tsx">
                <AuthProvider data-sentry-element="AuthProvider" data-sentry-source-file="ClientLayout.tsx">
                  <Navbar links={links} authenticatedMenuLinks={authenticatedMenuLinks} copyRight={copyRight} data-sentry-element="Navbar" data-sentry-source-file="ClientLayout.tsx" />
                  <CloudinaryAssetsManageContextProvider data-sentry-element="CloudinaryAssetsManageContextProvider" data-sentry-source-file="ClientLayout.tsx">
                    {children}
                  </CloudinaryAssetsManageContextProvider>
                  <GoTop data-sentry-element="GoTop" data-sentry-source-file="ClientLayout.tsx" />
                  {/* TO-DO: Add Hindi and Telugu language and add videos in languages accordingly */}
                  {/* <RtlSidebar /> */}
                  {/* <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent> */}
                </AuthProvider>
              </MenuContext.Provider>
            </main>
            <Footer data-sentry-element="Footer" data-sentry-source-file="ClientLayout.tsx" />
          </ApolloProvider>
        </Provider>
      </QueryClientProvider>
    </MantineProvider>;
}
export default Layout;