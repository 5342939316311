import api from '@whatsnxt/core-util/src/ApiClient/xiorInstance';
import {
  GET_CATEGORIES_QUERY,
  GET_CATEGORIES_COUNT_QUERY,
  EDIT_CATEGORY_MUTATION,
} from './gqlQueries/categoryQuery';

export const CategoryAPI = {
  getCategories: async function () {
    const { data } = await api.request({
      data: {
        query: GET_CATEGORIES_QUERY,
      },
    });
    return data.data ? data.data.categories : [];
  },

  getArticleCountByCategory: async function () {
    const { data } = await api.request({
      data: {
        query: GET_CATEGORIES_COUNT_QUERY,
      },
    });
    return data.data
      ? data.data.categoriesCount
      : [];
  },

  editCategory: async function ({
    categoryId,
    categoryName,
  }: {
    categoryId: string;
    categoryName: string;
  }) {
    const { data } = await api.request({
      data: {
        query: EDIT_CATEGORY_MUTATION,
        variables: {
          categoryId,
          categoryName,
        },
      },
    });
    return data.data;
  },
};
