'use client';

import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useDispatch } from 'react-redux';
import classes from '../Navbar.module.css';
import { Modal, Group, Menu, Button, Text, Burger, Space, Anchor, ThemeIcon, ActionIcon, rem, Box } from '@mantine/core';
import { IconLogout, IconSearch, IconX, IconArrowDown, IconPencil, IconCaretDown, IconSelector } from '@tabler/icons-react';
import useAuth from '@/hooks/Authentication/useAuth';
import SearchForm from '../../search-form/search-form';
import { useDisclosure } from '@mantine/hooks';
import { UserAPI } from '@/api/userApi';
import { AppDispatch } from '../../../store/store';
import { setSelectTag } from '../../../store/slices/contentSlice';
import { AuthenticatedUserType } from '@/context/Authentication/authTypes';
import { useRouter } from 'next/navigation';
import { Logo } from '@whatsnxt/core-ui';
import { notifications } from '@mantine/notifications';
type LinkType = {
  url: string;
  title: string;
  linkType: string;
};
interface INavbarDesktop {
  navLinks: LinkType[];
  authenticatedMenuLinks: LinkType[];
  initialUser?: AuthenticatedUserType | null;
  drawerOpened: any;
  toggleDrawer: any;
}
export const NavbarDesktop = ({
  navLinks,
  authenticatedMenuLinks,
  initialUser,
  drawerOpened,
  toggleDrawer
}: INavbarDesktop) => {
  const [user, setUser] = useState(initialUser ?? null); // Initialize user state
  const [opened, {
    open,
    close
  }] = useDisclosure(false);
  const {
    isAuthenticated,
    logout,
    loading
  } = useAuth();
  const [isSearch, setIsSearch] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const router = useRouter();
  const usernameInitials = user?.name ? user.name.split(' ').map((n: any) => n.charAt(0).toUpperCase()) : [];
  const usernameLabel = usernameInitials.length > 1 ? `${usernameInitials.at(0)}${usernameInitials.at(1)}` : usernameInitials.at(0);
  useEffect(() => {
    setUser(initialUser ?? null);
  }, [initialUser]);
  const updateUserType = async () => {
    try {
      const userRes = await UserAPI.updateUserType({
        creator: true,
        email: user?.email
      });
      if (userRes.creator) {
        setUser(userRes); // Update user state with the response
        router.push('/form');
      }
      close();
    } catch (error) {
      notifications.show({
        title: 'NON ADMIN EMAIL',
        message: error.message || 'Unable to activate writer mode',
        color: 'red'
      });
      console.log('🚀 ~ updateUserType ~ error:', error);
    }
  };
  const items = navLinks.map(link => {
    if (link.title === 'Write' && user) {
      return <Button size="md" key={link.title} component={Link} href={user?.creator ? link.url : ''} variant="subtle" style={{
        paddingTop: rem('8px'),
        paddingBottom: rem('8px'),
        paddingLeft: rem('12px'),
        paddingRight: rem('12px')
      }} onClick={() => {
        user?.creator ? undefined : open();
      }}>
          <IconPencil size={18} style={{
          marginRight: rem('5px')
        }} />
          {link.title}
        </Button>;
    }
    if (link.title !== 'Write') {
      return <Anchor onClick={() => {
        if (link.url === '/') dispatch(setSelectTag(null));
      }} component={Link} key={link.title} href={link.url} className={classes.link} style={{
        paddingTop: rem('8px'),
        paddingBottom: rem('8px'),
        paddingLeft: rem('12px'),
        paddingRight: rem('12px')
      }}>
          {link.title}
        </Anchor>;
    }
  });
  return <header className={classes.header} data-sentry-component="NavbarDesktop" data-sentry-source-file="index.tsx">
      <div className={classes.inner}>
        {isSearch ? <Box hiddenFrom="lg" display={'flex'} style={{
        alignItems: 'center',
        width: '100%'
      }}>
            <Box style={{
          width: '100%'
        }}>
              <SearchForm />
            </Box>
            <IconX size={25} onClick={() => {
          setIsSearch(prev => !prev);
        }} />
          </Box> : <>
            <Burger opened={drawerOpened} onClick={toggleDrawer} hiddenFrom="lg" />
            <ThemeIcon className={classes.logoContainer} variant='default'>
              <Anchor onClick={() => {
            dispatch(setSelectTag(null));
          }} component={Link} href='/'>
                <Logo />
              </Anchor>
            </ThemeIcon>
            <Group hiddenFrom="lg">
              <Group visibleFrom='sm'>
              </Group>
              <ActionIcon onClick={() => {
            setIsSearch(prev => !prev);
          }} size={34} variant="default" aria-label="ActionIcon with size as a number">
                <IconSearch />
              </ActionIcon>
            </Group>
          </>}

        <Modal opened={opened} onClose={close} centered data-sentry-element="Modal" data-sentry-source-file="index.tsx">
          <Text size="xl" ta="center" fw={700} data-sentry-element="Text" data-sentry-source-file="index.tsx">Your account is currently in reader mode.</Text>
          <Text size="xl" ta="center" fw={700} data-sentry-element="Text" data-sentry-source-file="index.tsx">Would you like to convert it to writer mode?</Text>
          <Group mt="xl" justify="center" data-sentry-element="Group" data-sentry-source-file="index.tsx">
            <Button onClick={updateUserType} data-sentry-element="Button" data-sentry-source-file="index.tsx">Yes</Button>
            <Button onClick={close} data-sentry-element="Button" data-sentry-source-file="index.tsx">No</Button>
          </Group>
        </Modal>

        {/* for desktop */}
        <Group visibleFrom="lg" wrap='nowrap' data-sentry-element="Group" data-sentry-source-file="index.tsx">
          <Box style={{
          width: '286px'
        }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
            <SearchForm data-sentry-element="SearchForm" data-sentry-source-file="index.tsx" />
          </Box>
          <Group ml={50} gap={5} data-sentry-element="Group" data-sentry-source-file="index.tsx">
            {items}
            <Space w="xl" data-sentry-element="Space" data-sentry-source-file="index.tsx" />
            {/* <ThemeSwitcher /> */}
            <Space w="xl" data-sentry-element="Space" data-sentry-source-file="index.tsx" />

            {/* ... menu items */}
            {isAuthenticated && <Menu shadow="md" width={200} trigger="click-hover" openDelay={100} closeDelay={400}>
                <Menu.Target>
                  <Button rightSection={<IconSelector size={16} />}>{usernameLabel}</Button>
                </Menu.Target>
                <Menu.Dropdown>
                  {authenticatedMenuLinks.map((link: any, index) => <Menu.Item key={index} component="a" href={link.url}>
                      {link.title}
                    </Menu.Item>)}
                  <Menu.Divider />
                  <Menu.Item onClick={logout} color="red" leftSection={<IconLogout size={18} />}>
                    Logout
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>}

            {!isAuthenticated && <Button loading={loading} disabled={loading} c="white" size={'sm'} component={Link} href="/auth/authentication">
                Login
              </Button>}
          </Group>
        </Group>
      </div>
    </header>;
};