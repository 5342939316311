import { Text, Container, ActionIcon, Group, Anchor, Box, List } from '@mantine/core';
import classes from './Footer.module.css';
import { Logo } from '@whatsnxt/core-ui';
import dynamic from 'next/dynamic';
import React, { useEffect } from 'react';
import { IconHome, IconInfoCircle, IconBook, IconMail, IconMapPin, IconPhone, IconBrandFacebook, IconBrandInstagram, IconBrandLinkedin, IconBrandTwitter, IconCopyright } from '@tabler/icons-react';

// Dynamically import the MobileFooter component and infer its type as a React component
const MobileFooter = dynamic(() => import('./MobileFooter').then(mod => mod.MobileFooter));
const MobileDetector = dynamic(() => import('./MobileDetector').then(mod => mod.MobileDetector), {
  ssr: false
});
export const Footer = () => {
  const [currentYear, setCurrentYear] = React.useState(new Date().getFullYear());
  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);
  const socialMediaPlatforms = [{
    icon: IconBrandFacebook,
    url: 'https://www.facebook.com/whatsnxt'
  }, {
    icon: IconBrandTwitter,
    url: 'https://www.twitter.com/whatsnxtsocial'
  }, {
    icon: IconBrandInstagram,
    url: 'https://www.instagram.com/whatsnxt.in'
  }, {
    icon: IconBrandLinkedin,
    url: 'https://www.linkedin.com/whatsnxtsocial'
  }];

  // Data for footer sections
  const footerSections = [{
    title: 'Explore',
    links: [{
      text: 'Home',
      link: '/',
      icon: IconHome
    }, {
      text: 'About',
      link: '/about',
      icon: IconInfoCircle
    }, {
      text: 'Courses',
      link: 'https://www.whatsnxt.in/courses',
      icon: IconBook
    }, {
      text: 'Contact',
      link: '/contact',
      icon: IconMail
    }]
  }, {
    title: 'Address',
    links: [{
      icon: IconMapPin,
      text: 'Hyderabad, India',
      link: 'https://maps.app.goo.gl/2o5hu5yMSc78Tz4W9'
    }, {
      icon: IconPhone,
      text: '+91 8790385964',
      link: 'tel:+44587154756'
    }, {
      icon: IconMail,
      text: 'info@whatsnxt.in',
      link: 'mailto:info@whatsnxt.in'
    }]
  }];
  return <Box className={classes['footer-area']} data-sentry-element="Box" data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      <Container className="container" data-sentry-element="Container" data-sentry-source-file="Footer.tsx">
        <MobileDetector data-sentry-element="MobileDetector" data-sentry-source-file="Footer.tsx">
          {(isMobile: boolean) => isMobile ? <MobileFooter footerSections={footerSections} socialMediaPlatforms={socialMediaPlatforms} /> : <div className={classes['footer-grid-container']}>
                <section>
                  <div className={classes['single-footer-widget']}>
                    <Logo color="white" className="w-75 mb-3" />
                    <Text>
                      Working to bring significant changes in online-based learning by
                      doing extensive research for course curriculum preparation,
                      student engagements, and looking forward to the flexible
                      education!
                    </Text>
                    <Group gap={10} className={classes.social} justify="flex-start" wrap="nowrap">
                      {socialMediaPlatforms.map((link, i) => <ActionIcon key={i} size="lg" color="white" variant="subtle" className={classes.socialIcon} component="a" href={link.url} target="_blank" rel="noopener noreferrer">
                          <link.icon size={16} />
                        </ActionIcon>)}
                    </Group>
                  </div>
                </section>
                {footerSections.map((section, index) => <Box key={index}>
                    <div className={classes['single-footer-widget']}>
                      <>
                        <Text size="xl" fw={800}>{section.title}</Text>
                        <List className={classes['footer-links-list']}>
                          {section.links?.map((info, i) => <List.Item key={i}>
                              <Group>
                                {info.icon && <span className={classes['footer-icon']}>
                                    <info.icon size={16} />
                                  </span>}
                                <Anchor className="text-decoration-none" href={info.link} target="_blank">{info.text}</Anchor>
                              </Group>
                            </List.Item>)}
                        </List>
                      </>
                    </div>
                  </Box>)}
              </div>}
        </MobileDetector>
      </Container>

      {/* Updated Box for footer bottom with flexbox for better alignment */}
      <Box className={classes['footer-bottom-area']} data-sentry-element="Box" data-sentry-source-file="Footer.tsx">
        <Container className="container" data-sentry-element="Container" data-sentry-source-file="Footer.tsx">
          <Box className={classes['footer-bottom-wrapper']} style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center'
        }} data-sentry-element="Box" data-sentry-source-file="Footer.tsx">
            <Text className="d-flex align-items-center gap-1" data-sentry-element="Text" data-sentry-source-file="Footer.tsx">
              <IconCopyright size={18} data-sentry-element="IconCopyright" data-sentry-source-file="Footer.tsx" />
              {currentYear} whatsnxt
              <Anchor target="_blank" href="https://www.whatsnxt.in/" className="text-decoration-none" data-sentry-element="Anchor" data-sentry-source-file="Footer.tsx">
                Copyright reserved
              </Anchor>
            </Text>
            <List className={classes['footer-policy-links']} style={{
            display: 'flex',
            gap: '10px',
            marginTop: '10px',
            flexWrap: 'wrap',
            justifyContent: 'center'
          }} data-sentry-element="List" data-sentry-source-file="Footer.tsx">
              <List.Item data-sentry-element="unknown" data-sentry-source-file="Footer.tsx">
                <Anchor className="text-decoration-none" href="/privacy-policy" data-sentry-element="Anchor" data-sentry-source-file="Footer.tsx">Privacy Policy</Anchor>
              </List.Item>
              <List.Item data-sentry-element="unknown" data-sentry-source-file="Footer.tsx">
                <Anchor className="text-decoration-none" href="/terms-of-service" data-sentry-element="Anchor" data-sentry-source-file="Footer.tsx">Terms & Conditions</Anchor>
              </List.Item>
              <List.Item data-sentry-element="unknown" data-sentry-source-file="Footer.tsx">
                <Anchor className="text-decoration-none" href="/refund-policy" data-sentry-element="Anchor" data-sentry-source-file="Footer.tsx">Refund Policy</Anchor>
              </List.Item>
            </List>
          </Box>
        </Container>
      </Box>

      <div className={classes.lines}>
        <div className={classes.line}></div>
        <div className={classes.line}></div>
        <div className={classes.line}></div>
      </div>
    </Box>;
};