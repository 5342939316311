export const POST_BLOG_MUTATION = () => `
  mutation CreateBlog(
    $title: String!,
    $description: String!,
    $categoryName: String!,
    $imageUrl: String!,
    $wordCount: Int,
    $categoryId: String,
    $subCategory: String,
    $nestedSubCategory: String,
    $cloudinaryAssets: [CloudinaryAssetsInput!]
  ) {
    addPost(
      title: $title,
      description: $description,
      categoryName: $categoryName,
      wordCount: $wordCount,
      imageUrl: $imageUrl,
      categoryId: $categoryId,
      subCategory: $subCategory,
      nestedSubCategory: $nestedSubCategory,
      cloudinaryAssets: $cloudinaryAssets
    ) {
      _id
    }
  }
`;

export const EDIT_BLOG_MUTATION = () => `
  mutation EditBlog(
    $id: String!,
    $title: String!,
    $description: String!,
    $categoryName: String!,
    $wordCount: Int,
    $imageUrl: String,
    $categoryId: String!,
    $subCategory: String,
    $nestedSubCategory: String,
    $cloudinaryAssets: [CloudinaryAssetsInput!]
  ) {
    editPost(
      id: $id,
      title: $title,
      description: $description,
      categoryName: $categoryName,
      categoryId: $categoryId,
      subCategory: $subCategory,
      wordCount: $wordCount,
      imageUrl: $imageUrl,
      nestedSubCategory: $nestedSubCategory,
      cloudinaryAssets: $cloudinaryAssets
    ) {
      _id
    }
  }
`;

export const POST_TUTORIAL_MUTATION = () => `
  mutation PostTutorial(
    $title: String!,
    $description: String!,
    $categoryName: String!,
    $categoryId: String!,
    $subCategory: String,
    $nestedSubCategory: String,
    $tutorials: [Tutorial!]!,
    $published: Boolean!,
    $imageUrl: String!,
    $cloudinaryAssets: [CloudinaryAssetsInput!]
  ) {
    addTutorial(
      title: $title,
      description: $description,
      categoryName: $categoryName,
      categoryId: $categoryId,
      subCategory: $subCategory,
      nestedSubCategory: $nestedSubCategory,
      tutorials: $tutorials,
      published: $published,
      imageUrl: $imageUrl,
      cloudinaryAssets: $cloudinaryAssets
    ) {
      _id
    }
  }
`;

export const EDIT_TUTORIAL_MUTATION = () => `
  mutation EditTutorial(
    $id: String!,
    $title: String!,
    $description: String!,
    $categoryName: String!,
    $categoryId: String!,
    $subCategory: String,
    $nestedSubCategory: String,
    $tutorials: [Tutorial!]!,
    $published: Boolean!,
    $imageUrl: String,
    $cloudinaryAssets: [CloudinaryAssetsInput!]
  ) {
    editTutorial(
      id: $id,
      title: $title,
      description: $description,
      categoryName: $categoryName,
      categoryId: $categoryId,
      subCategory: $subCategory,
      nestedSubCategory: $nestedSubCategory,
      tutorials: $tutorials,
      published: $published,
      imageUrl: $imageUrl,
      cloudinaryAssets: $cloudinaryAssets
    ) {
      _id
    }
  }
`;
