import api from '@whatsnxt/core-util/src/ApiClient/xiorInstance';

export const ElasticSearchAPI = {
  search: async function (searchString: string) {
    const response = await api.request({
      url: `/content/search?title=${searchString}`,
      method: 'GET',
    });
    return response?.data || [];
  },
};
