'use client';

import { startNavigationProgress, completeNavigationProgress, resetNavigationProgress } from '@mantine/nprogress';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
export default function CustomProgressBar() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  useEffect(() => {
    // Start progress when path or search params change
    startNavigationProgress();
    const timer = setTimeout(() => {
      completeNavigationProgress(); // Complete to 100%
    }, 500);
    return () => {
      clearTimeout(timer);
      resetNavigationProgress(); // Ensure progress bar is reset
    };
  }, [pathname, searchParams]);
  return null;
}