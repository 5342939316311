export const GET_CATEGORIES_QUERY = () => `
  query GetCategories {
    categories {
      _id
      categoryName
      subcategories {
        name
        subcategories {
          name
        }
      }
    }
  }
`;

export const GET_CATEGORIES_COUNT_QUERY = () => `
  query GetCategoriesCount {
    categoriesCount {
      categoryName
      categoryId
      count
    }
  }
`;

export const EDIT_CATEGORY_MUTATION = () => `
  mutation EditCategory(
    $categoryId: String!,
    $categoryName: String!
  ) {
    editCategory(
      categoryId: $categoryId,
      categoryName: $categoryName
    ) {
      _id
      categoryName
    }
  }
`;
