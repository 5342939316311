import api from '@whatsnxt/core-util/src/ApiClient/xiorInstance';
import { getAlgoliaIndex } from '../api/algolia/getAlgoliaIndex';

import {
  DELETE_POST_MUTATION,
  DELETE_TUTORIAL_MUTATION,
  PUBLISH_POST_MUTATION,
  LIST_POST_MUTATION,
  DOWNLOAD_EBOOK_QUERY,
  GET_POSTS_HISTORY_QUERY,
  CREATE_TUTORIAL_FROM_BLOGS,
} from './gqlQueries/historyQuery';
import { historyFilterType } from '../types/history';

const index = getAlgoliaIndex();

export const HistoryAPI = {
  getPosts: async function (
    start: number,
    limit: number,
    search: string,
    filter?: historyFilterType,
  ) {
    const { data } = await api.request({
      data: {
        query: GET_POSTS_HISTORY_QUERY,
        variables: { start, limit, type: 'both', search },
      },
    }) as { data: any };

    return data.data ? data.data.retrieveContentHistory : [];
  },

  createTutorialFromBlogs: async function (list: string[], title: string) {
    const { data } = await api.request({
      data: {
        query: CREATE_TUTORIAL_FROM_BLOGS,
        variables: { blogIds: list, title },
      },
    }) as { data: any };

    return data.data ? data.data.CreateTutorialFromBlogs : {};
  },

  downloadEBook: async function (id: string) {
    const { data } = await api.request({
      data: {
        query: DOWNLOAD_EBOOK_QUERY,
        variables: { id },
      },
    }) as { data: any };

    return data.data ? data.data.generateEbook : '';
  },

  deleteBlog: async function (id: string) {
    const { data } = await api.request({
      data: {
        query: DELETE_POST_MUTATION,
        variables: { postId: id },
      },
    }) as { data: any };

    if (data?.deletePost) index.deleteObject(id);
    return data.data ? data.data.deletePost : '';
  },

  deleteTutorial: async function (id: string) {
    const { data } = await api.request({
      data: {
        query: DELETE_TUTORIAL_MUTATION,
        variables: { tutorialId: id },
      },
    }) as { data: any };

    if (data?.deleteTutorial) index.deleteObject(id);
    return data.data ? data.data.deleteTutorial : '';
  },

  publishBlog: async function (id: string, publish: boolean) {
    const { data } = await api.request({
      data: {
        query: PUBLISH_POST_MUTATION,
        variables: { postId: id, publish },
      },
    }) as { data: any };

    return data.data ? data.data.publishPost : '';
  },

  listBlog: async function (id: string, list: boolean) {
    const { data } = await api.request({
      data: {
        query: LIST_POST_MUTATION,
        variables: { postId: id, list },
      },
    }) as { data: any };

    return data.data ? data.data.listPost : '';
  },
};
