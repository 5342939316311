import xior from 'xior';
import api from '@whatsnxt/core-util/src/ApiClient/xiorInstance';
import { DELETE_IMAGE_MUTATION, DELETE_MULTIPLE_ASSETS_MUTATION } from './gqlQueries/cloudinaryQuery';

// Get the Cloudinary upload URL based on the resource type
export const getUploadUrl = (type: 'image' | 'raw' | 'video'): string => {
  if (type === 'image') return process.env.NEXT_PUBLIC_CLOUDINARY_IMAGE_UPLOAD_URL!;
  if (type === 'video') return process.env.NEXT_PUBLIC_CLOUDINARY_VIDEO_UPLOAD_URL!;
  if (type === 'raw') return process.env.NEXT_PUBLIC_CLOUDINARY_FILE_UPLOAD_URL!;
  return process.env.NEXT_PUBLIC_CLOUDINARY_IMAGE_UPLOAD_URL!;
};

// Fallback for progress tracking with XMLHttpRequest
const uploadWithProgress = (formData: FormData, onUploadProgress: (progressEvent: any) => void, type: 'image' | 'raw' | 'video'): Promise<any> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const uploadUrl = getUploadUrl(type);

    xhr.open('POST', uploadUrl, true);

    // Attach progress event
    xhr.upload.onprogress = onUploadProgress;

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(JSON.parse(xhr.responseText));
      } else {
        reject(xhr.statusText);
      }
    };

    xhr.onerror = () => {
      reject(xhr.statusText);
    };

    xhr.setRequestHeader('Accept', 'application/json');
    xhr.send(formData);
  });
};


export const CloudinaryAPI = {
  upload: async function (
    formData: FormData,
    onUploadProgress?: (progressEvent: any) => void,
    type: 'image' | 'raw' | 'video' = 'image'
  ) {
    try {
      if (onUploadProgress) {
        // Use XMLHttpRequest for uploads with progress tracking
        return await uploadWithProgress(formData, onUploadProgress, type);
      } else {
        // Use Xior for uploads without progress tracking
        const response = await xior.request({
          url: getUploadUrl(type),
          method: 'POST',
          data: formData,
          withCredentials: false,
        });
        return response?.data || [];
      }
    } catch (error) {
      console.log('🚀 ~ error:', error)
    }
  },

  uploadFormData: async function (formData: any, type: 'image' | 'raw' | 'video' = 'image') {
    console.log('getUploadUrl(type) ', getUploadUrl(type))
    const response = await api.request({
      url: getUploadUrl(type),
      method: 'POST',
      data: formData,
      withCredentials: false,
    });
    return response?.data || null;
  },

  // Deleting single asset
  delete: async function (id: string) {
    const { data } = await api.request({
      data: {
        query: DELETE_IMAGE_MUTATION,
        variables: { deleteID: id },
      },
    });
    return data.data ? data.data.deleteImage : false;
  },

  // Deleting multiple assets
  deleteMultipleAssets: async function (assets: { type: string, id: string }[], accessToken: string) {
    const res = await api.request({
      data: {
        query: DELETE_MULTIPLE_ASSETS_MUTATION,
        variables: { assets },
        accessToken: accessToken
      },
    },);
    return res.data.data ? res.data.data.deleteMultiAssets : false;
  },

  uploadFormImage: async function (image: File, resourceType: string, folderName = 'whatsnxt-blog') {
    try {
      const formData = new FormData();
      formData.append("file", image, image.name);
      formData.append('resource_type', resourceType);
      formData.append('folder_name', folderName);

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      const fileUploadRes = await fetch(`${process.env.NEXT_PUBLIC_BFF_HOST_API}/cloudinary/upload-image`, requestOptions)
      return fileUploadRes.json();
    } catch (error) {
      throw error
    }
  },

};

